import {
	required,
	requiredIf
} from "vuelidate/lib/validators";

export default {
	name: "admin-formulario-tema",
	data() {
		return {
			arAssuntos: [],
			blLoading: true,
			blDialogExcluir: false,
			blExcluindo: false,
			blErro: false,
			blSalvando: false,
			obForm: {
				id: 0,
				nome: "",
				id_assunto: "",
				link: "",
				icone: null,
				inativo:true,
				observacao:"",
				ordem: 0
			}
		};
	},
	validations: {
		obForm: {
			nome: {
				required
			},
			id_assunto: {
				required
			},
			icone: {
				required: requiredIf(function (form) {
					return this.isLoggedIn && form.change_password
				})
			},
			ordem: {
				required
			}
		}
	},
	created() {
		if (this.$route.params.id)
			this.obter(this.$route.params.id);
		else
			this.obter(0);
	},
	methods: {
		obter(id) {
			this.$root.$api.get("tema/obter/" + id).then(response => {
				if (response.retorno.tema) {
					this.$root.$utils.preencheFormularios(
						this.obForm,
						response.retorno.tema
					);
				}

				this.arAssuntos = response.retorno.assuntos;

				this.blLoading = false;
			});
		},
		mensagemErro(strCampo) {
			return this.$utils.handleErrors(strCampo, this);
		},
		validarFormulario() {
			this.blErro = false;

			this.$v.obForm.$touch();

			if (this.$v.obForm.$invalid) {
				this.blErro = true;
			}
		},
		submit() {
			this.validarFormulario();

			if (this.blErro) {
				return false;
			}

			this.blSalvando = true;
			if(this.obForm.inativo) {
				this.obForm.inativo = 1;
			}
			this.$root.$api
				.file("tema/salvar", this.obForm)
				.then(response => {
					this.blSalvando = false;

					this.$router.push({
						name: "admin-tema"
					});
				});
		},
		excluir() {
			this.blExcluindo = true;

			this.$root.$api
				.delete("tema/excluir/" + this.obForm.id)
				.then(response => {
					this.blDialogExcluir = false;

					this.$router.push({
						name: "admin-tema"
					});
				});
		}
	}
};